import React from 'react';
import SinglePoem from '../../../components/singlePoem';
import Paper from '@mui/material/Paper'

export default function Poem() {
  return (
    <SinglePoem title="The Immolation of the Martyr Jan Palach 
    and His Subsequent Journey to His Namesake Asteroid, 1969" >
        <p>
        He doused his head, his limbs,<br></br>
        his Czech face, and<br></br>
        ignited the wet body,<br></br>
        synthetic bloc-fabrics<br></br>
        melting and cooking<br></br>
        with the meat<br></br>
        of his angry torso,<br></br>
        emitting acrid fumes,<br></br>
        stinging the nostrils, the throats<br></br>
        of the planet—<br></br>
        the sad smoke<br></br>
        causing the tearing of<br></br>
        a million eyes, a<br></br>
        tearing of a million meters<br></br>
        of inorganic fibers<br></br>
        on it way into the sky,<br></br>
        the choking remains<br></br>
        of the boy<br></br>
        traveling upward into the<br></br>
        heavens, outward into space,<br></br>
        searching for a new home,<br></br>
        a new ground on which<br></br>
        to rest its mean, its free,<br></br>
        its weary particles.<br></br>
        </p>
    </SinglePoem>
      );
}
